<template>
  <div class="speaking-two-sentence-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
    ></PageButton>
    <div class="game-content">
      <SceneSwitchThumbnails
        :buttons="buttonList"
        :numBers="currentIndex"
        @changeTheNumbers="changeTheNumbers"
      >
      </SceneSwitchThumbnails>
      <div class="title-area">
        <div class="title">
          <div class="title-pinyin pinyin font-pinyin-medium">
            {{ titleInfo.pinyin }}
          </div>
          <div class="title-hanzi font-hanzi-medium">
            {{ titleInfo.hanzi }}
          </div>
        </div>
      </div>
      <div class="bgImg-area">
        <template v-for="(item, index) in gameList">
          <div
            class="img-box"
            :key="index"
            v-if="index + 1 === currentIndex"
            @click="handleClickImg(item)"
          >
            <transition name="el-fade-in">
              <img :src="item.isFirst ? item.firstImg : item.bgImg" alt="" />
            </transition>
          </div>
        </template>
      </div>

      <div class="bottom-sentence-area">
        <div
          class="hanzi-item"
          v-for="(item, index) in gameList"
          :key="index"
          v-show="item.isShowSentence"
        >
          <div class="pinyin sentence-hanzi font-pinyin-medium">
            {{ item.isFirst ? item.firstPinyin : item.pinyin }}
          </div>
          <div class="sentence-hanzi font-hanzi-medium">
            {{ item.isFirst ? item.firstHanzi : item.hanzi }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import SceneSwitchThumbnails from "@/components/Course/CoursePage/StorySwitcher/sceneSwitchThumbnails.vue";
export default {
  name: "SpeakingSentence",
  props: {
    gameList: {
      type: Array,
      require: true,
      default: () => [],
    },
    buttonList: {
      type: Array,
      require: true,
      default: () => [],
    },
    titleInfo: {
      type: Object,
      default: () => {},
    },
    isBlueBackground: {
      type: Boolean,
      default: false,
    },
    lesson: {
      type: Number,
      require: true,
    },
  },
  data() {
    return {
      currentIndex: 1,
      isLastStep: false,
      clickMenuSocketInfo: {},
      clickImgSocketInfo: {},
      lock: false,
      isSecondImage: false,

      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "speak",
            startNumberRange: 0,
            endNumberRange: 100,
          },
        ],
      },
    };
  },
  components: {
    PageButton,
    SceneSwitchThumbnails,
  },
  watch: {
    clickMenuSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2004601,
          data: { value },
          text: "SpeakingSentence点击Menu socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    clickImgSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2004602,
          data: { value },
          text: "SpeakingSentence点击Img socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  mounted() {
    this.$bus.$on("speakingSentenceMenu", ({ index }) => {
      this.changeTheNumbers(index, true);
    });
    this.$bus.$on("clickSpeakingSentenceImg", () => {
      this.handleClickImg("socket", true);
    });
  },
  beforeDestroy() {
    this.$bus.$off("speakingSentenceMenu");
    this.$bus.$off("clickSpeakingSentenceImg");
  },

  methods: {
    changeTheNumbers(index, isFromSocket = false) {
      console.log(index);
      this.$emit("initGame");
      this.lock = false;
      this.currentIndex = index;
      if (!isFromSocket) {
        this.clickMenuSocketInfo = {
          index,
          value: Math.random(),
        };
      }
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    handleClickImg(socket, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickImgSocketInfo = {
          value: Math.random(),
        };
      }
      if (this.lock) {
        this.$emit("showSecondImg", this.currentIndex);
      }
      this.$emit("showSentence", this.currentIndex);
      if (
        this.currentIndex === this.gameList.length &&
        this.gameList[this.currentIndex - 1].isShowSentence === true &&
        this.lock
      ) {
        this.isLastStep = true;
        playCorrectSound();
        startConfetti();
      } else if (this.gameList[this.currentIndex - 1].isFirst || this.isSecondImage) {
        if (this.isSecondImage) { this.isSecondImage = false;}
        playCorrectSound(true, false);
      }
      if (this.gameList[this.currentIndex - 1].isFirst) {
        this.isSecondImage = true;
      }
      this.lock = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.speaking-two-sentence-container {
  .game-content {
    width: 100%;
    height: 100%;
    position: relative;
    .bgImg-area {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      .img-box {
        cursor: pointer;
        width: 100%;
        height: 100%;
        img {
          border-radius: 58px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .title-area {
      z-index: 9;
      position: absolute;
      top: 0;
      left: 0;
      top: 10%;
      width: 18%;
      height: 17%;
      background: #cd4c3f;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      color: #fff;
      .title {
        .title-pinyin,
        .title-hanzi {
          text-align: center;
        }
      }
    }

    .bottom-sentence-area {
      position: absolute;
      width: 100%;
      height: 20%;
      bottom: 4%;
      display: flex;
      justify-content: center;
      align-items: center;
      .hanzi-item {
        // width: 50%;
        margin: 0 auto;
        background: #cd4c3f;
        position: absolute;
        padding: 1% 15%;
        color: #fff;
        border-radius: 40px;
        .sentence-hanzi,
        .sentence-pinyin {
          text-align: center;
        }
      }
    }
  }
}
</style>